<template>
    <span class="navbar-text">
        <slot></slot>
    </span>
</template>

<script>
    export default {
        props: {}
    };
</script>
