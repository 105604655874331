<template>
    <div class="input-group-btn">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {}
    };
</script>
